import { Box, Column, numericUtil, Row } from '@cmg/common';
import { ipoPricingScreenSelector } from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';

import { IpoPricing } from '../../../../types/domain/datalab/ipo-pricing/charts';
import { selectIpoPricingCharts } from '../../ducks';
import SingleVerticalBarChart from '../widgets/charts/SingleVerticalBarChart';
import WidgetAftermarketPerformanceRelativeToSizeAndPricing from '../widgets/WidgetAftermarketPerformanceRelativeToSizeAndPricing/WidgetAftermarketPerformanceRelativeToSizeAndPricing';
import WidgetBarMeanMedianPercentage from '../widgets/WidgetBarMeanMedianPercentage/WidgetBarMeanMedianPercentage';
import WidgetPerformanceHeatMapBySector from '../widgets/WidgetPerformanceHeatMapBySector/WidgetPerformanceHeatMapBySector';
import WidgetPricingVsInitialRange from '../widgets/WidgetPricingVsInitialRange/WidgetPricingVsInitialRange';
import WidgetVolumeDropoffBySector from '../widgets/WidgetVolumeDropoffBySector/WidgetVolumeDropoffBySector';

type Props = {
  datalab: IpoPricing;
};

export class IpoPricingScreenComponent extends React.Component<Props> {
  render() {
    const { datalab } = this.props;

    return (
      <div data-test-id={ipoPricingScreenSelector.testId}>
        <Row>
          <Column xs={24}>
            <Box>
              <WidgetPricingVsInitialRange chartData={datalab.pricing_vs_initial_range} />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetBarMeanMedianPercentage
                chartData={datalab.offer_to_midpoint_percent_change}
                title="Offer to Midpoint % Change"
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <SingleVerticalBarChart
                rawData={datalab.percent_of_ipos_trading_above_offer}
                title="% of IPOs Trading Above Offer"
                yLabelFormatter={value => numericUtil.formatPercents(value, 1)}
                valueFormatter={numericUtil.formatPercents}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetPerformanceHeatMapBySector
                chartData={datalab.performance_heat_map_by_sector}
                useRuby={true}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetAftermarketPerformanceRelativeToSizeAndPricing
                chartData={datalab.after_market_relative_to_pricing}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <SingleVerticalBarChart
                rawData={datalab.first_day_turnover}
                title="First Day Turnover By Sector"
                yLabelFormatter={numericUtil.formatMultipleFactor}
                valueFormatter={value => numericUtil.formatNumber(value, 1)}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetVolumeDropoffBySector chartData={datalab.volume_dropoff_by_sector} />
            </Box>
          </Column>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    datalab: selectIpoPricingCharts(state),
  };
}

export default connect(mapStateToProps, undefined)(IpoPricingScreenComponent);
