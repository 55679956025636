import { type Calendar_OfferingFieldsFragment } from '../graphql';
import { useSellingRestrictionVisibility } from '../model/column-visibility';

type Props = {
  offering: Calendar_OfferingFieldsFragment;
};

const SellingRestrictionRenderer = ({ offering }: Props) => {
  const { canReadConverts, canReadIntl } = useSellingRestrictionVisibility();

  const regS = offering.attributes?.isRegS && canReadIntl && 'Reg S';

  const rule144A = offering.attributes?.isRule144A && (canReadIntl || canReadConverts) && '144A';

  return (
    <div>
      {regS}
      {regS && rule144A && ', '}
      {rule144A}
    </div>
  );
};

export default SellingRestrictionRenderer;
