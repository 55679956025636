import { Grid } from '@cmg/design-system';
import React from 'react';

import { OfferingProfile_ListQuery } from '../../graphql/__generated__/OfferingProfile';
import {
  useGetAdvisorsData,
  useGetCompanySecurityData,
  useGetTimingData,
} from '../shared/model/Shared.model';
import {
  spacing,
  x4_l4_m6_s12,
  x4_l12_m12_s12,
  x6_l6_m12_s12,
  x8_l8_m6_s12,
  x8_l12_m12_s12,
  x12_l6_m12_s12,
  x12_l12_m12_s12,
} from '../shared/OfferingProfile.styles';
import OfferingProfileCardItem from '../shared/OfferingProfileCardItem';
import ManagersWidget from '../shared/widgets/ManagersWidget';
import NewsWidget from '../shared/widgets/NewsWidget';
import TermsWidget from '../shared/widgets/TermsWidget';
import UnderwriterWidget from '../shared/widgets/UnderwriterWidget';
import UserNotesWidget from '../shared/widgets/UserNotesWidget';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  userNote?: string | null;
  onSaveUserNote: (userNote: string | null) => void;
  isSavingUserNote: boolean;
};

export const ConvertProfile: React.FC<Props> = ({
  offeringProfile,
  userNote,
  onSaveUserNote,
  isSavingUserNote,
}) => {
  const timing = useGetTimingData(offeringProfile);
  const companySecurity = useGetCompanySecurityData(offeringProfile);
  const advisers = useGetAdvisorsData(offeringProfile);
  return (
    <Grid container {...spacing} alignItems="stretch" direction="row">
      {/* Widget Row 1 */}
      <OfferingProfileCardItem
        title="Company/Security"
        cardSizes={x4_l4_m6_s12}
        rowData={companySecurity}
      />
      <Grid container item {...x8_l8_m6_s12} {...spacing} alignItems="stretch" direction="row">
        <OfferingProfileCardItem
          title="Timing"
          dataDescription="All dates are presented in exchange country's local time zone."
          cardSizes={x6_l6_m12_s12}
          rowData={timing}
        />
        <NewsWidget cardSizes={x6_l6_m12_s12} offeringProfile={offeringProfile} />
      </Grid>
      {/* Widget Row 2 */}
      <TermsWidget cardSizes={x12_l12_m12_s12} offeringProfile={offeringProfile} />
      {/* Widget Row 3 */}
      <Grid container item {...x8_l12_m12_s12}>
        <UnderwriterWidget cardSizes={x12_l12_m12_s12} offeringProfile={offeringProfile} />
        <ManagersWidget cardSizes={x12_l12_m12_s12} offeringProfile={offeringProfile} />
      </Grid>
      <Grid container item {...spacing} {...x4_l12_m12_s12}>
        <OfferingProfileCardItem title="Advisors" cardSizes={x12_l6_m12_s12} rowData={advisers} />
        <UserNotesWidget
          cardSizes={x12_l6_m12_s12}
          onSaveUserNote={onSaveUserNote}
          isSaving={isSavingUserNote}
          userNote={userNote}
        />
      </Grid>
    </Grid>
  );
};
