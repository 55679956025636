import { Box, Divider, Grid, MenuItem, MenuList, Stack, Typography } from '@cmg/design-system';
import React from 'react';

import { useActiveSectionOnScroll } from '../../../../../common/hooks/useActiveSectionOnScroll';
import { OfferingType } from '../../../../../graphql/__generated__';
import ValueHighlight from '../../../shared/components/value-highlight/ValueHighlight';
import { OfferingProfile_ListQuery } from '../../graphql/__generated__/OfferingProfile';
import { AtmStructurePricingWidget } from '../atm/widgets/AtmStructurePricingWidget';
import { AtmTimingWidget } from '../atm/widgets/AtmTimingWidget';
import { AtmUnderWritingTermsWidget } from '../atm/widgets/AtmUnderwritingTermsWidget';
import {
  profileSectionPrefix,
  useGetProfileSections,
  useGetSideBarData,
} from './model/Shared.model';
import {
  dividerStyle,
  mainSectionBoxStyle,
  menuListStyle,
  sideNavBoxStyle,
  x12_l12_m12_s12,
} from './OfferingProfile.styles';
import { OfferingProfileFieldName } from './types';
import AdvisersWidget from './widgets/AdvisersWidget';
import CornerstoneInvestorsWidget from './widgets/CornerstoneInvestorsWidget';
import DocumentationWidget from './widgets/DocumentationWidget';
import LockupsWidget from './widgets/LockupsWidget';
import ManagersList from './widgets/ManagersListWidget';
import ManagersWidget from './widgets/ManagersWidgetV2';
import PerformanceWidget from './widgets/PerformanceWidget';
import ShareholdersWidget from './widgets/ShareholdersWidget';
import StructurePricingWidget from './widgets/StructurePricingWidget';
import TimingWidget from './widgets/TimingWidget';
import UnderwritingTermsWidget from './widgets/UnderwritingTermsWidget';
import UserNotesWidget from './widgets/UserNotesWidget';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  userNote?: string | null;
  onSaveUserNote: (userNote: string | null) => void;
  isSavingUserNote: boolean;
};

export const OfferingProfile: React.FC<Props> = ({
  offeringProfile,
  userNote,
  onSaveUserNote,
  isSavingUserNote,
}) => {
  const [mainSectionHeight, setmainSectionHeight] = React.useState('100vh');
  const offeringData = useGetSideBarData(offeringProfile);
  const sectionsConfig = useGetProfileSections(offeringProfile);
  const mainSectionId = 'offering_profile_main_section';
  const { activeSection, handleClick } = useActiveSectionOnScroll({
    sectionsConfig,
    mainSectionId,
  });
  const { type } = offeringProfile.offeringById || {};

  React.useEffect(() => {
    const mainSection = document.getElementById(mainSectionId);
    const handleResize = () => {
      mainSection && setmainSectionHeight(`calc(100vh - ${mainSection.offsetTop}px - 1px)`);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window?.removeEventListener('resize', handleResize);
    };
  }, []);

  const valueHighlightItemHeight = (name: string) =>
    OfferingProfileFieldName.ExchRegionCountry !== name ? 24 : undefined;

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      divider={<Divider orientation="vertical" flexItem />}
    >
      <Box sx={sideNavBoxStyle} height={mainSectionHeight}>
        {offeringData.map(({ name, value, info }) => (
          <ValueHighlight
            key={name}
            label={name}
            value={value}
            info={info}
            height={valueHighlightItemHeight(name)}
          />
        ))}
        <Divider orientation="horizontal" sx={dividerStyle} />
        <MenuList sx={menuListStyle}>
          {sectionsConfig.map(section => {
            const isSelected = activeSection === section.id;
            return (
              <MenuItem
                key={section.id}
                selected={isSelected}
                onClick={() => {
                  handleClick(section);
                }}
              >
                <Typography
                  variant={isSelected ? 'highlight1' : 'body1'}
                  color={theme =>
                    isSelected ? theme.palette.primary.main : theme.palette.text.primary
                  }
                >
                  {section.name}
                </Typography>
              </MenuItem>
            );
          })}
        </MenuList>
      </Box>
      <Box sx={mainSectionBoxStyle} id={mainSectionId} maxHeight={mainSectionHeight}>
        {type === OfferingType.Atm && (
          <React.Fragment>
            <AtmTimingWidget
              cardSizes={x12_l12_m12_s12}
              offeringProfile={offeringProfile}
              id={`${profileSectionPrefix}_timing`}
            />
            <AtmStructurePricingWidget
              cardSizes={x12_l12_m12_s12}
              offeringProfile={offeringProfile}
              id={`${profileSectionPrefix}_structure`}
            />
            <PerformanceWidget
              cardSizes={x12_l12_m12_s12}
              offeringProfile={offeringProfile}
              id={`${profileSectionPrefix}_performance`}
            />
            <ManagersList
              cardSizes={x12_l12_m12_s12}
              offeringProfile={offeringProfile}
              id={`${profileSectionPrefix}_sales_agents`}
            />
            <AtmUnderWritingTermsWidget
              cardSizes={x12_l12_m12_s12}
              offeringProfile={offeringProfile}
              id={`${profileSectionPrefix}_underwriting_terms`}
            />
            <AdvisersWidget
              cardSizes={x12_l12_m12_s12}
              offeringProfile={offeringProfile}
              id={`${profileSectionPrefix}_advisor`}
            />
            <Grid container columnSpacing={3}>
              <Grid item xl={6} xs={12}>
                <DocumentationWidget
                  cardSizes={x12_l12_m12_s12}
                  offeringProfile={offeringProfile}
                  id={`${profileSectionPrefix}_documentation`}
                />
              </Grid>
              <Grid item xl={6} xs={12}>
                <UserNotesWidget
                  cardSizes={x12_l12_m12_s12}
                  onSaveUserNote={onSaveUserNote}
                  userNote={userNote}
                  isSaving={isSavingUserNote}
                  id={`${profileSectionPrefix}_notes`}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {type !== OfferingType.Atm && (
          <React.Fragment>
            <TimingWidget
              cardSizes={x12_l12_m12_s12}
              offeringProfile={offeringProfile}
              id={`${profileSectionPrefix}_timing`}
            />
            <StructurePricingWidget
              cardSizes={x12_l12_m12_s12}
              offeringProfile={offeringProfile}
              id={`${profileSectionPrefix}_structure`}
            />
            <PerformanceWidget
              cardSizes={x12_l12_m12_s12}
              offeringProfile={offeringProfile}
              id={`${profileSectionPrefix}_performance`}
            />
            <ManagersWidget
              cardSizes={x12_l12_m12_s12}
              offeringProfile={offeringProfile}
              id={`${profileSectionPrefix}_managers`}
            />
            <UnderwritingTermsWidget
              cardSizes={x12_l12_m12_s12}
              offeringProfile={offeringProfile}
              id={`${profileSectionPrefix}_underwritingTerms`}
            />
            <LockupsWidget
              cardSizes={x12_l12_m12_s12}
              offeringProfile={offeringProfile}
              id={`${profileSectionPrefix}_lockups`}
            />
            <ShareholdersWidget
              cardSizes={x12_l12_m12_s12}
              offeringProfile={offeringProfile}
              id={`${profileSectionPrefix}_shareholders`}
            />
            <CornerstoneInvestorsWidget
              cardSizes={x12_l12_m12_s12}
              offeringProfile={offeringProfile}
              id={`${profileSectionPrefix}_cornerstone`}
            />
            <AdvisersWidget
              cardSizes={x12_l12_m12_s12}
              offeringProfile={offeringProfile}
              id={`${profileSectionPrefix}_advisor`}
            />
            <Grid container columnSpacing={3}>
              <Grid item xl={6} xs={12}>
                <DocumentationWidget
                  cardSizes={x12_l12_m12_s12}
                  offeringProfile={offeringProfile}
                  id={`${profileSectionPrefix}_documentation`}
                />
              </Grid>
              <Grid item xl={6} xs={12}>
                <UserNotesWidget
                  cardSizes={x12_l12_m12_s12}
                  onSaveUserNote={onSaveUserNote}
                  isSaving={isSavingUserNote}
                  userNote={userNote}
                  id={`${profileSectionPrefix}_notes`}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Box>
    </Stack>
  );
};
