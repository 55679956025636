import { numericUtil, timeUtil } from '@cmg/common';
import React from 'react';
import { Link } from 'react-router-dom';

import { formatNullableBoolean } from '../../../common/helpers/helpers';
import routeFactory from '../../../common/util/routeFactory';
import { ISODate, ISODateTime } from '../../../types/common';
import { CalendarCategory } from '../../../types/domain/calendar/constants';
import { DatalabScreens } from '../../datalab/constants';
import {
  canViewConvertData,
  isConvertsPhaseTwoOn,
  isInternationalOfferingsOn,
} from '../../datalab/model/utils';
import {
  getCurrencyFormat,
  getCurrencyFormatInMillions,
  getFormattedPercentageRange,
} from '../../shared/model/utils';
import CornerstoneInvestorsRenderer from '../components/CornerstoneInvestorsRenderer';
import CountryRenderer from '../components/CountryRenderer';
import HeaderLabelRenderer from '../components/HeaderLabelRenderer';
import IndicationTooltip from '../components/IndicationTooltip';
import IssuerFirmNameRenderer from '../components/IssuerFirmNameRenderer';
import LeftLeadFirmNameRenderer from '../components/LeftLeadFirmNameRenderer';
import OfferingProfileLinkRenderer from '../components/OfferingProfileLinkRenderer';
import PerformancePercentsRenderer from '../components/PerformancePercentsRenderer';
import PriceRangeRenderer from '../components/PriceRangeRenderer';
import SellingRestrictionRenderer from '../components/SellingRestrictionRenderer';
import ShareholderRenderer from '../components/ShareholderRenderer';
import SizeSharesRenderer from '../components/SizeSharesRenderer';
import {
  type Calendar_OfferingFieldsFragment,
  Country,
  Currency,
  OfferingStatus,
  OfferingType,
} from '../graphql';
import { getCurrentDatalabScreen } from '../utils';
import { FilterValues } from './calendar-filters';

type Renderer = (
  v: any,
  row: Calendar_OfferingFieldsFragment,
  _?: any,
  __?: any,
  metaData?: any
) => React.ReactNode;

export type Column = {
  field: string;
  label: string;
  renderer?: Renderer;
  groupHeaderRenderer?: Renderer;
  headerRenderer?: Renderer;
  fixedMinWidth?: number;

  /**
   * Enable/Disable Grouping on the column header
   * see usage of this field here:
   * @type HeaderItem in HeaderItem.js
   */
  canGroup?: boolean;

  /**
   * Enable/Disable Sorting on the column header
   * see usages of this field here:
   * @type HeaderItem in HeaderItem.js for VirtualizedTable
   * @type Header in Header.js for StandardTable
   */
  disableSort?: boolean;
  textAlign?: string;
};

export const tbdValue = 'TBD';

const formatDateWithDay = (date: ISODate | ISODateTime | null | undefined) =>
  date && timeUtil.formatAsDisplayDate(date)
    ? `${timeUtil.formatAsDisplayDay(date)}, ${timeUtil.formatAsDisplayDate(date)}`
    : null;

export const issuerFirmNameColumn: Column = {
  field: 'issuer.name',
  label: 'Issuer',
  renderer: (_v, row, _, __, metaData) => (
    <IssuerFirmNameRenderer
      offering={row}
      showIoiBadge
      onToggleFollowOffering={metaData.handleFollowClick}
    />
  ),
};

export const issuerFirmNameForMyOfferingColumn: Column = {
  field: 'issuer.name',
  label: 'Issuer',
  renderer: (_v, row, _, __, metaData) => (
    <IssuerFirmNameRenderer
      offering={row}
      showLive
      onToggleFollowOffering={metaData.handleFollowClick}
    />
  ),
};

export const tickerColumn: Column = {
  field: 'issuer.primarySymbol',
  label: 'Ticker',
  renderer: (_v, row) => {
    const { symbol, issuer, attributes } = row;

    if (attributes?.exchangeCountry === Country.Us && attributes.status === OfferingStatus.Filed) {
      return symbol && symbol.length > 0 ? symbol : issuer?.primarySymbol ?? '';
    }

    return (
      <OfferingProfileLinkRenderer
        offering={row}
        label={symbol && symbol.length > 0 ? symbol : issuer?.primarySymbol ?? ''}
      />
    );
  },
};

export const sectorColumn: Column = {
  field: 'issuer.sectorDisplayName',
  label: 'Sector',
  canGroup: true,
  renderer: (_v, row) => row.issuer?.sectorDisplayName,
};

export const customSectorColumn: Column = {
  field: 'issuer.customSectors.customSector.name',
  label: 'Sector',
  canGroup: true,
  renderer: (_v, row) => row.issuer?.customSectors?.customSector?.name,
};

export const offeringTypeColumn: Column = {
  field: 'attributes.typeDisplayName',
  label: 'Type',
  canGroup: true,
  renderer: v => v,
};

export const expectedPricingDateColumn: Column = {
  field: 'attributes.pricingDate',
  label: 'Expected Pricing Date',
  fixedMinWidth: 85,
  renderer: v => timeUtil.formatAsDisplayDate(v) || tbdValue,
  canGroup: true,
  groupHeaderRenderer: v => formatDateWithDay(v) || tbdValue,
  headerRenderer: () => (
    <HeaderLabelRenderer label="Pricing Date" tooltip="Expected Pricing Date" />
  ),
};

export const pricingDateColumn: Column = {
  field: 'attributes.pricingDate',
  label: 'Pricing Date',
  fixedMinWidth: 85,
  renderer: v => timeUtil.formatAsDisplayDate(v) || tbdValue,
  canGroup: true,
  groupHeaderRenderer: v => formatDateWithDay(v) || tbdValue,
};

const getMarketCapTooltip = () => {
  const screen = getCurrentDatalabScreen();
  switch (screen) {
    case CalendarCategory.LIVE:
    case CalendarCategory.POSTPONED:
      return 'Market Cap in USD is calculated using the exchange rate on the later of filing or launch date.';
    default:
      return 'Market Cap in USD is calculated using the exchange rate as of end of day on the later of filing or launch date.';
  }
};

export const marketCapColumn: Column = {
  // TODO (ECM-42976): confirm the field should be marketCapPreOffering vs marketCapAtPricing with *Usd
  field: 'attributes.marketCapAtPricingUsd',
  label: 'Market Cap ($M)',
  fixedMinWidth: 110,
  textAlign: 'right',
  renderer: v => numericUtil.formatCurrencyInMillions(v, true),
  headerRenderer: _v => (
    <HeaderLabelRenderer label="Market Cap ($M)" tooltip={getMarketCapTooltip()} />
  ),
};

export const marketCapAtPricingColumn: Column = {
  field: 'attributes.marketCapAtPricingUsd',
  label: 'Market Cap ($M)',
  fixedMinWidth: 110,
  textAlign: 'right',
  renderer: v => numericUtil.formatCurrencyInMillions(v, true),
};

const getSizeUsdTooltip = () => {
  const screen = getCurrentDatalabScreen();
  switch (screen) {
    case CalendarCategory.FILED:
      return 'Size in USD is calculated using the exchange rate on the filing date.';
    case CalendarCategory.LIVE:
    case CalendarCategory.POSTPONED:
      return 'Size in USD is calculated using the exchange rate on the later of filing or launch date.';
    default:
      return 'Size in USD is calculated using the exchange rate as of end of day on the filing date.';
  }
};

export const sizeLocalCurrColumn: Column = {
  field: 'attributes.latestGrossProceedsTotal',
  label: 'Size',
  fixedMinWidth: 110,
  textAlign: 'right',
  renderer: (_v, row) =>
    getCurrencyFormatInMillions({
      value: row.attributes?.latestGrossProceedsTotal,
      pricingCurrencyCode: row.attributes?.pricingCurrency ?? 'USD',
      showInternational: true,
      keepSmallValues: true,
    }),
};

export const sizeDollarsColumn: Column = {
  field: 'attributes.latestGrossProceedsTotalUsd',
  label: 'Size ($M)',
  fixedMinWidth: 110,
  textAlign: 'right',
  renderer: v => numericUtil.formatCurrencyInMillions(v, true),
  headerRenderer: () => <HeaderLabelRenderer label="Size ($M)" tooltip={getSizeUsdTooltip()} />,
};

const isUsFiled = ({ attributes }: Calendar_OfferingFieldsFragment) =>
  attributes?.status === OfferingStatus.Filed && attributes?.exchangeCountry === Country.Us;

export const sizeSharesColumn: Column = {
  field: 'attributes.latestSizeInSecuritiesTotal',
  label: 'Size (Shares)',
  fixedMinWidth: 120,
  textAlign: 'right',
  renderer: (_v, row) => (isUsFiled(row) ? '-' : <SizeSharesRenderer offering={row} />),
};

export const priceRangeLowColumn: Column = {
  field: 'attributes.priceRangeLow',
  label: 'Price Range',
  disableSort: true, // price range is computed, and is in different currencies on each row, doesn't make sense to sort
  fixedMinWidth: 150,
  textAlign: 'right',
  renderer: (_v, row) => (isUsFiled(row) ? '-' : <PriceRangeRenderer offering={row} />),
};

export const priceRangeLivePricedColumn: Column = {
  field: 'priceRangeLivePricedColumn', // note this field does not exist in the dataset
  label: 'Price Range',
  disableSort: true, // price range is computed, and is in different currencies on each row, doesn't make sense to sort
  fixedMinWidth: 150,
  textAlign: 'left',
  renderer: (_v, row) => {
    const {
      latestCouponTalkPercentageLow,
      latestCouponTalkPercentageHigh,
      latestPremiumTalkLowPercentage,
      latestPremiumTalkHighPercentage,
    } = row.attributes || {};
    return row.attributes?.type === OfferingType.Convertible ? (
      `${getFormattedPercentageRange(
        latestCouponTalkPercentageLow,
        latestCouponTalkPercentageHigh,
        3
      )} / ${getFormattedPercentageRange(
        latestPremiumTalkLowPercentage,
        latestPremiumTalkHighPercentage,
        2
      )}`
    ) : (
      <PriceRangeRenderer offering={row} />
    );
  },
};

export const leftLeadColumn: Column = {
  field: 'attributes.leftLeadName',
  label: 'Left Lead',
  renderer: (_v, row) => (isUsFiled(row) ? '-' : <LeftLeadFirmNameRenderer offering={row} />),
  canGroup: true,
  groupHeaderRenderer: (_v, row) =>
    isUsFiled(row) ? (
      '-'
    ) : (
      <Link
        to={routeFactory.datalab.getUrlPath({
          screen: DatalabScreens.UNDERWRITER_OFFERINGS,
          type: 'table',
          query: { underwriter: row.attributes?.leftLeadId },
        })}
      >
        {row.attributes?.leftLeadName}
      </Link>
    ),
};

export const shareholderColumn: Column = {
  field: 'attributes.primaryShareholderName',
  label: 'Shareholder',
  renderer: (_v, row) => <ShareholderRenderer offering={row} />,
  canGroup: true,
  groupHeaderRenderer: (_v, row) => (
    <Link
      to={routeFactory.datalab.getUrlPath({
        screen: DatalabScreens.MARKET_PULSE,
        type: 'table',
        query: {
          sponsor: row.attributes?.primaryShareholderFirmId,
        },
      })}
    >
      {row.attributes?.primaryShareholderName}
    </Link>
  ),
};

export const offerPriceColumn: Column = {
  field: 'attributes.latestOfferPriceUsd',
  label: 'Offer Price',
  fixedMinWidth: 85,
  textAlign: 'right',
  renderer: v => numericUtil.formatCurrency(v),
};

export const offerToCurrentColumn: Column = {
  field: 'attributes.pctOfferToCurrent',
  label: 'To Current',
  fixedMinWidth: 65,
  textAlign: 'right',
  renderer: (v, row) => (
    <PerformancePercentsRenderer
      value={v}
      tooltipValue={row.attributes?.currentSharePrice}
      tooltipCurrency={row.attributes?.pricingCurrency}
    />
  ),
};

export const firstTradeDateColumn: Column = {
  field: 'attributes.firstTradeDate',
  label: 'First Trade Date',
  fixedMinWidth: 85,
  renderer: v => timeUtil.formatAsDisplayDate(v) ?? tbdValue,
  canGroup: true,
  groupHeaderRenderer: v => formatDateWithDay(v) ?? tbdValue,
};

export const discountToLastColumn: Column = {
  field: 'attributes.pctToLastTrade',
  label: 'To Last Trade',
  fixedMinWidth: 65,
  textAlign: 'right',
  renderer: (v, row) => (
    <PerformancePercentsRenderer
      value={v}
      tooltipValue={row.attributes?.lastTradeBeforeOffer}
      tooltipCurrency={row.attributes?.pricingCurrency}
    />
  ),
};

export const offerTo1dayColumn: Column = {
  field: 'attributes.pctOfferTo1Day',
  label: 'To 1 Day',
  fixedMinWidth: 65,
  textAlign: 'right',
  renderer: (v, row) => (
    <PerformancePercentsRenderer
      value={v}
      tooltipValue={row.attributes?.day1SharePrice}
      tooltipCurrency={row.attributes?.pricingCurrency}
    />
  ),
};

export const publicFilingDateColumn: Column = {
  field: 'attributes.publicFilingDate',
  label: 'Filing Date',
  fixedMinWidth: 85,
  renderer: v => timeUtil.formatAsDisplayDate(v),
  canGroup: true,
  groupHeaderRenderer: v => formatDateWithDay(v),
};

export const postponedDateColumn: Column = {
  field: 'attributes.postponedDate',
  label: 'Postponed Date',
  fixedMinWidth: 85,
  renderer: v => timeUtil.formatAsDisplayDate(v),
  canGroup: true,
  groupHeaderRenderer: v => formatDateWithDay(v),
};

export const lockUpExpirationDateColumn: Column = {
  field: 'attributes.lockUpExpirationDate',
  label: 'Lock-Up Expiration Date',
  fixedMinWidth: 85,
  renderer: v => timeUtil.formatAsDisplayDate(v),
  canGroup: true,
};

export const allocationColumn: Column = {
  field: 'firmMetric.allocation',
  label: 'Allocation',
  fixedMinWidth: 90,
  textAlign: 'right',
  renderer: (v, row) => (
    <Link to={routeFactory.ioi.getUrlPath({ id: row.id })}>{numericUtil.formatInteger(v)}</Link>
  ),
};

export const ioiTypeColumn: Column = {
  field: 'lastAllocation.ioiType',
  label: 'Indication',
  fixedMinWidth: 90,
  textAlign: 'right',
  renderer: (v, row) => (
    <Link to={routeFactory.ioi.getUrlPath({ id: row.id })}>
      <IndicationTooltip indicationType={v} offering={row} />
    </Link>
  ),
};

export const secondarySharesColumn: Column = {
  field: 'attributes.latestPctSecondaryShares',
  label: '% Secondary',
  fixedMinWidth: 90,
  textAlign: 'right',
  renderer: (v, row) => (isUsFiled(row) ? '-' : numericUtil.formatPercents(v, 1)),
};

export const totalBookRunnersColumn: Column = {
  field: 'attributes.totalBookrunners',
  label: '# of Bookrunners',
  fixedMinWidth: 90,
  textAlign: 'right',
  renderer: (v, row) => (isUsFiled(row) ? '-' : numericUtil.formatInteger(v)),
};

export const totalNonBookRunnersColumn: Column = {
  field: 'attributes.totalNonBookrunners',
  label: '# of Non-Bookrunners',
  fixedMinWidth: 90,
  textAlign: 'right',
  renderer: (v, row) => (isUsFiled(row) ? '-' : numericUtil.formatInteger(v)),
};

export const hasCornerstoneColumn: Column = {
  field: 'attributes.hasCornerstoneInvestors',
  label: 'Cornerstone Investors (Y/N)',
  renderer: (_v, row) => <CornerstoneInvestorsRenderer offering={row} />,
  headerRenderer: () => (
    <HeaderLabelRenderer label="Cornerstone" tooltip="Cornerstone Investors (Y/N)" />
  ),
};

export const conditionalLockUpColumn: Column = {
  field: 'attributes.isConditionalLockUp',
  label: 'Conditional Lock-up',
  fixedMinWidth: 70,
  textAlign: 'right',
  renderer: v => formatNullableBoolean(v),
};

export const multipleLockUpsColumn: Column = {
  field: 'attributes.hasMultipleLockUps',
  label: 'Multiple Lock-ups',
  fixedMinWidth: 70,
  textAlign: 'right',
  renderer: v => formatNullableBoolean(v),
};

export const regionColumn: Column = {
  field: 'attributes.exchangeRegionDisplayName',
  label: 'Exchange Region',
  canGroup: true,
  renderer: v => v,
  headerRenderer: () => <HeaderLabelRenderer label="Region" tooltip="Exchange Region" />,
};

export const countryColumn: Column = {
  field: 'attributes.exchangeCountryDisplayName',
  label: 'Exchange Country',
  canGroup: true,
  renderer: (v, row) => (
    <CountryRenderer
      exchangeCountryDisplayName={v}
      exchangeCountry={row.attributes?.exchangeCountry}
    />
  ),
  headerRenderer: () => <HeaderLabelRenderer label="Country" tooltip="Exchange Country" />,
};

export const offerPriceLocalCurrColumn: Column = {
  field: 'attributes.latestOfferPrice',
  label: 'Offer Price',
  fixedMinWidth: 85,
  textAlign: 'right',
  renderer: (_v, row) =>
    getCurrencyFormat({
      value: row.attributes?.latestOfferPrice,
      pricingCurrencyCode: row.attributes?.pricingCurrency ?? Currency.Usd,
      showInternational: true,
    }),
};

export const marketCapAtPricingLocalCurrColumn: Column = {
  field: 'attributes.marketCapAtPricing',
  label: 'Market Cap',
  fixedMinWidth: 110,
  textAlign: 'right',
  renderer: (_v, row) =>
    getCurrencyFormatInMillions({
      value: row.attributes?.marketCapAtPricing,
      pricingCurrencyCode: row.attributes?.pricingCurrency ?? Currency.Usd,
      showInternational: true,
      keepSmallValues: true,
    }),
};

export const couponColumn: Column = {
  field: 'finalTerm.couponPercentage',
  label: 'Coupon',
  fixedMinWidth: 90,
  textAlign: 'right',
  renderer: v => numericUtil.formatPercents(v, 3),
};

export const premiumColumn: Column = {
  field: 'finalTerm.premiumPercentage',
  label: 'Premium',
  fixedMinWidth: 90,
  textAlign: 'right',
  renderer: v => numericUtil.formatPercents(v, 2),
};

export const conversionPriceColumn: Column = {
  field: 'finalTerm.conversionPrice',
  label: 'Conversion Price',
  fixedMinWidth: 85,
  textAlign: 'right',
  renderer: (_v, row) =>
    getCurrencyFormat({
      value: row.finalTerm?.conversionPrice,
      pricingCurrencyCode: row.attributes?.pricingCurrency ?? Currency.Usd,
      showInternational: true,
    }),
};

export const sellingRestrictionColumn: Column = {
  field: 'sellingRestrictionColumn', // note this field does not exist in the dataset
  label: 'Selling Restrictions',
  disableSort: true,
  renderer: (_v, row) => <SellingRestrictionRenderer offering={row} />,
  headerRenderer: () => <HeaderLabelRenderer label="S/R" tooltip="Selling Restrictions" />,
};

export const convertibleFields: string[] = [
  couponColumn.field,
  premiumColumn.field,
  conversionPriceColumn.field,
];

export const internationalFields: string[] = [
  regionColumn.field,
  countryColumn.field,
  offerPriceLocalCurrColumn.field,
  sizeDollarsColumn.field,
  marketCapAtPricingLocalCurrColumn.field,
];

export const internationalFieldForLiveFiledPostponed: string[] = [
  regionColumn.field,
  countryColumn.field,
  offerPriceLocalCurrColumn.field,
  sizeDollarsColumn.field,
  marketCapColumn.field,
];

export const getInternationalFields = (category: CalendarCategory) => {
  if (
    category === CalendarCategory.LIVE ||
    category === CalendarCategory.FILED ||
    category === CalendarCategory.POSTPONED
  ) {
    return internationalFieldForLiveFiledPostponed;
  }

  return internationalFields;
};

export const getColumnsConfig = (
  columns: Column[],
  category: CalendarCategory,
  filters: FilterValues
): Column[] => {
  const showInternational = isInternationalOfferingsOn();
  const showConvertPhaseTwo = isConvertsPhaseTwoOn();

  let columnsConfig = showInternational
    ? columns.map(overrideIntlProps)
    : columns.filter(col => !getInternationalFields(category).includes(col.field));

  if (canViewConvertData()) {
    columnsConfig = columnsConfig.map(overrideConvertibleProps);
  }

  if (!showConvertPhaseTwo) {
    columnsConfig = columnsConfig.filter(col => !convertibleFields.includes(col.field));
  } else if (filters.offeringType.toString() !== 'CONVERTIBLE') {
    // filters out conversionPricecolumn if CONVERTIBLE is not the only Offering Type filter selected
    columnsConfig = columnsConfig.filter(col => col.field !== conversionPriceColumn.field);
  }
  return columnsConfig;
};

export type overridingProp = {
  [x: string]: Partial<Column>;
};

const intlOverridingProps: overridingProp = {
  [offerPriceColumn.field]: {
    label: 'Offer Price $',
  },
};

export const overrideIntlProps = (column: Column): Column =>
  Object.keys(intlOverridingProps).includes(column.field)
    ? {
        ...column,
        ...intlOverridingProps[column.field],
      }
    : column;

const convertibleOverridingProps: overridingProp = {
  [priceRangeLivePricedColumn.field]: {
    fixedMinWidth: 225,
  },
};

export const overrideConvertibleProps = (column: Column): Column =>
  Object.keys(convertibleOverridingProps).includes(column.field)
    ? {
        ...column,
        ...convertibleOverridingProps[column.field],
      }
    : column;
