import { Box, InfoOutlinedIcon, Link, Tooltip, Typography } from '@cmg/design-system';
import React from 'react';

interface ViewingOfferingsMessageProps {
  viewingCurrent: number;
  viewingTotal: number;
  onResetFilters: () => void;
}

const ViewingOfferingsMessage: React.FC<ViewingOfferingsMessageProps> = ({
  viewingCurrent,
  viewingTotal,
  onResetFilters,
}) => {
  const handleResetClick = () => {
    onResetFilters();
  };

  return (
    <Box
      display="flex"
      marginTop={theme => theme.spacing(1.75)}
      marginRight={theme => theme.spacing(2)}
    >
      <Typography
        variant="body2"
        paddingRight={theme => theme.spacing(0.5)}
        color={theme => theme.palette.text.primary}
      >
        Viewing {viewingCurrent}/{viewingTotal} Offerings
      </Typography>
      <Tooltip
        title={
          <React.Fragment>
            Adjust your filter selection to view all offerings.{' '}
            <Link
              component="button"
              variant="body2"
              onClick={e => {
                e.preventDefault();
                handleResetClick();
              }}
            >
              Reset Filters
            </Link>
          </React.Fragment>
        }
        arrow
      >
        <InfoOutlinedIcon aria-label="info-icon" fontSize="inherit" color="action" />
      </Tooltip>
    </Box>
  );
};

export default ViewingOfferingsMessage;
